(fn => (document.readyState !== 'loading') ? fn() : document.addEventListener('DOMContentLoaded', fn))(()=>{

  /* over close button */
  const closeBtns = document.querySelectorAll('.overlay .btn-close');
  closeBtns.forEach(ele=>{
    ele.addEventListener('click', () =>{
      const parent = ele.parentNode;
      if(!parent)return;
      parent.classList.remove('active');
      document.body.classList.remove('overlay-active');
    })
  });

  //lazyload
  function lazyLoad(){
    document.querySelectorAll('.lazy').forEach(el =>{
      if(el.getBoundingClientRect().top < (window.innerHeight * 2)){
        el.classList.remove('lazy', 'lazy-bg');
      }
    })
  }
  window.addEventListener('scroll', () => lazyLoad());
  lazyLoad();

  //enable carousel swipe
  document.querySelectorAll('.screen .carousel').forEach(el =>{
    $(el).carousel();
  });

  const scrollHint = document.querySelector('#scroll-hint');
  if(scrollHint){
    scrollHint.addEventListener('click', ()=>{
      window.scrollTo({top: window.innerHeight, behavior: "smooth"});
    })
  }

  window.addEventListener('scroll', () => {
    if(window.scrollY <= 5 && document.body.classList.contains('scrolled')){
      document.body.classList.remove('scrolled');
    }
    if(window.scrollY > 5 && !document.body.classList.contains('scrolled')){
      document.body.classList.add('scrolled');
    }
  })

});


function isAndroid(){
  return (/Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
}

if(/Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
  document.documentElement.style.setProperty('--vh', `${window.innerHeight + 56}px`);
}else if(/iPhone|iPad|iPod/i.test){
  document.documentElement.style.setProperty('--vh', `100vh`);
}else{
  document.documentElement.style.setProperty('--vh', `100vh`);
}
